import React from "react";
import {
  Grid,
  Dialog,
  CircularProgress
} from "@mui/material";

const LoadingDialog = ({ open, message }) => {
  return (
    <Dialog
      open={open}
      onClose={() => { }}
      disableEscapeKeyDown={true}
    >
      <Grid container padding={2} sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
      <Grid container padding={2}>
        {message}
      </Grid>
    </Dialog>
  )
}

export default LoadingDialog;