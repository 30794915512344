import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import {
  Add,
} from "@mui/icons-material"
import EvaluationActivityTemplate from "./EvaluationActivityTemplate";

const EvaluationView = ({
  name,
  setName,
  description,
  setDescription,
  activities,
  addActivity,
  updateActivity,
  deleteActivity,
  evaluation
}) => {
  const { t } = useTranslation("evaluations_new");

  return (<Grid
    container
    sx={{
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "94vh",
      overflow: "auto"
    }}>
    <Grid
      container
      item
      direction={"column"}
      padding={2}
      spacing={2}
      md={7}
      xs={12}
    >
      <Grid item>
        <TextField
          disabled={!!evaluation}
          variant="outlined"
          label={t("template.name")}
          value={evaluation ? evaluation.name : name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          disabled={!!evaluation}
          variant="outlined"
          label={t("template.description")}
          value={evaluation ? evaluation.description : description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      {
        evaluation?.activities.length > 0 ?
          evaluation.activities.map((item, index) => (
            <EvaluationActivityTemplate
              key={item._id}
              activity={item}
              index={index}
              readOnly={true}
            />
          )) : activities.map((item, index) => (
            <EvaluationActivityTemplate
              key={item.key}
              activity={item}
              index={index}
              updateActivity={updateActivity}
              canDelete={activities.length > 1}
              deleteActivity={deleteActivity}
            />
          ))
      }
      {!evaluation && <Grid
        item
        container
        sx={{
          justifyContent: "flex-end"
        }}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={addActivity}
          >
            {t("template.add")}
          </Button>
        </Grid>
      </Grid>}
    </Grid >
  </Grid>)
}

export default EvaluationView;