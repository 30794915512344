import React, { useState } from "react"
import axios from "axios"
import {
  Grid,
  Button,
  TextField,
  Divider
} from "@mui/material";
import {
  Search
} from "@mui/icons-material"
import { useTranslation } from "react-i18next";
import EvaluationSelectionModal from "./EvaluationSelectionModal";
import ResultTemplateText from "./ResultTemplateText";
import ResultTemplateSingle from "./ResultTemplateSingle";
import ResultTemplateMultiple from "./ResultTemplateMultiple";
import ResultTemplateRange from "./ResultTemplateRange";
import ResultTemplateSlider from "./ResultTemplateSlider";

const EvaluationResultModal = ({
  patient,
  results,
  setResults,
  resultView = null
}) => {
  const { t } = useTranslation("patient_evaluation");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const [evaluations, setEvaluations] = useState(null);
  const [chosenEvaluation, setChosenEvaluation] = useState(resultView?.evaluationTemplate || null);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchEvaluations = async (page) => {
    setLoading(true)
    try {
      const response = await axios.get(`/evaluations_new?page=${page}`)
      setEvaluations(response.data.evaluations)
      setTotalPages(response.data.pages)
      setCurrentPage(page)
    } catch (error) {
      setErrorDialog(true)
    } finally {
      setLoading(false)
    }
  }

  const openDialog = async () => {
    setDialogOpen(true)
    if (!evaluations) {
      await fetchEvaluations(currentPage)
    }
  }

  const closeDialog = (_event, reason) => {
    if (loading && reason === "backdropClick") {
      return;
    }
    setDialogOpen(false)
  }

  const getEvaluationsFromPage = async (_event, page) => {
    setLoading(true)
    await fetchEvaluations(page)
  }

  const selectEvaluation = (item) => {
    if (chosenEvaluation?._id === item._id) {
      return;
    }
    setChosenEvaluation(item)
    const emptyResults = item.activities.map((act) => {
      return {
        activityId: act._id,
        params: act.params.map((param) => {
          return {
            paramId: param._id,
            value: null
          }
        })
      }
    })
    setResults({
      evaluationTemplateId: item._id,
      results: emptyResults
    })
  }

  const updateResults = (values, index) => {
    const newResults = [...results.results];
    newResults[index] = {
      activityId: newResults[index].activityId,
      params: values
    }
    setResults({
      evaluationTemplateId: null,
      results: newResults
    });
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent:
            "center",
          maxHeight: "94vh",
          overflow: "auto"
        }}>
        <Grid container item direction="column" spacing={1} padding={2} xs={12} md={7}>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={true}
                variant="outlined"
                label={t("evaluation.patient")}
                value={`${patient.name} ${patient.lastname}`}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={9} md={4}>
              <TextField
                disabled={true}
                variant="outlined"
                label={t("evaluation.evaluation")}
                value={chosenEvaluation ? chosenEvaluation.name : ""}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              {!resultView && <Grid item>
                <Button
                  variant="contained"
                  startIcon={<Search />}
                  fullWidth={true}
                  onClick={openDialog}
                >
                  {t("evaluation.select")}
                </Button>
              </Grid>}
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {chosenEvaluation && (<Grid item container direction="column" spacing={1}>
            {chosenEvaluation.activities.map((item, index) => (
              <Grid item key={item._id}>
                {item.type === "TEXT" && <ResultTemplateText
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  number={false}
                  activityResult={resultView?.results[index] || null}
                />}
                {item.type === "NUMBER" && <ResultTemplateText
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  number={true}
                  activityResult={resultView?.results[index] || null}
                />}
                {item.type === "SINGLE" && <ResultTemplateSingle
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  activityResult={resultView?.results[index] || null}
                />}
                {item.type === "MULTIPLE" && <ResultTemplateMultiple
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  activityResult={resultView?.results[index] || null}
                />}
                {item.type === "RANGE" && <ResultTemplateRange
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  activityResult={resultView?.results[index] || null}
                />}
                {item.type === "SLIDER" && <ResultTemplateSlider
                  activity={item}
                  index={index}
                  sendResults={updateResults}
                  activityResult={resultView?.results[index] || null}
                />}
              </Grid>
            ))}
          </Grid>)}
        </Grid >
      </Grid>
      <EvaluationSelectionModal
        loading={loading}
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        errorDialog={errorDialog}
        evaluations={evaluations}
        currentPage={currentPage}
        totalPages={totalPages}
        getEvaluationsFromPage={getEvaluationsFromPage}
        chooseEvaluation={selectEvaluation}
      />
    </>
  )
}

export default EvaluationResultModal;