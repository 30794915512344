import React, { useState } from "react"
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";

const ResultTemplateMultiple = ({
  activity,
  index,
  sendResults,
  activityResult = null
}) => {
  const [result, setResult] = useState(
    activityResult?.params.map((item) => item.value) || Array(activity.params.length).fill(false)
  )

  const updateResult = (value, position) => {
    const resultCopy = [...result]
    resultCopy[position] = value;
    setResult(resultCopy)
    const newResults = activity.params.map((param, idx) => {
      return {
        paramId: param._id,
        value: resultCopy[idx]
      }
    })
    sendResults(newResults, index)
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container direction="column" padding={2} spacing={1}>
          <Grid item container sx={{ justifyContent: "flex-end" }}>
            <Grid item>
              <Typography variant="subtitle1">
                {index + 1}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {activity.name}
            </Typography>
          </Grid>
          {activity.description && (<Grid item>
            <Typography variant="body2">
              {activity.description}
            </Typography>
          </Grid>)}
          <Grid item>
            <FormGroup
            >
              {activity.params.map((param, index) => (<FormControlLabel
                disabled={!!activityResult}
                key={param._id}
                value={result[index]}
                onChange={(e) => updateResult(e.target.checked, index)}
                control={<Checkbox
                  checked={result[index]}
                />}
                label={param.name}
              />))}
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ResultTemplateMultiple;