import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material"

const SelectionTemplate = ({
  values,
  updateParams,
  readOnly = false
}) => {
  const { t } = useTranslation("evaluations_new");

  const [params, setParams] = useState(values)

  useEffect(() => {
    updateParams(params)
  }, [params])

  const addSelectionParam = () => {
    setParams([...params, { name: "", expectedValue: "bool" }])
  }

  const removeSelectionParam = () => {
    setParams(Array.from(params).slice(0, -1))
  }

  const updateSelectionParam = (value, index) => {
    const paramCopy = params.map((item, i) => {
      if (index === i) {
        return { ...item, name: value };
      }
      return item
    })
    setParams(paramCopy)
  }

  return (
    <>
      <Grid container padding={2} direction="column">
        {params.map((item, index) => (
          <Grid
            key={index}
            item
            container
            padding={2}
            spacing={2}>
            <Grid
              item
              container
              xs={1}
              sx={{ alignItems: "center", }}
            >
              <Grid item>
                {index + 1}.-
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <TextField
                disabled={readOnly}
                variant="outlined"
                label={t("template.activity.option")}
                value={item.name}
                onChange={(e) => updateSelectionParam(e.target.value, index)}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {!readOnly && <Grid
        container
        padding={2}
        spacing={2}
        sx={{
          justifyContent: "flex-end"
        }}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={addSelectionParam}
          >
            {t("template.activity.optionAdd")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Delete />}
            onClick={removeSelectionParam}
            disabled={params.length < 3}
          >
            {t("template.activity.optionDelete")}
          </Button>
        </Grid>
      </Grid>}
    </>
  )
}

export default SelectionTemplate;