import React, { useState, useEffect } from "react";
import axios from "axios";
import { getUserId } from "../../lib/router";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  Divider,
  Button,
  Typography,
  Pagination
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  ErrorOutline
} from "@mui/icons-material"
import FullScreenModal from "../../components/FullScreenModal"
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import EvaluationList from "./components/EvaluationList";
import EvaluationView from "./components/EvaluationView";

const Evaluations = () => {
  const { t } = useTranslation("evaluations_new");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadingEvaluations, setLoadingEvaluations] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("errorLoading"))

  const [evaluations, setEvaluations] = useState([]);

  const [chosenEvaluation, setChosenEvaluation] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [activityKey, setActivityKey] = useState(1);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activities, setActivities] = useState([
    {
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }
  ]);

  const resetState = () => {
    setActivityKey(1)
    setName("")
    setDescription("")
    setActivities([{
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }])
  }

  const openDialog = () => {
    setChosenEvaluation(null);
    setDialogOpen(true);
  }

  const closeDialog = () => { setDialogOpen(false) }

  const fetchEvaluations = async (page) => {
    setLoadingEvaluations(true);
    try {
      const response = await axios.get(`/evaluations_new?page=${page}`);
      setEvaluations(response.data.evaluations);
      setTotalPages(response.data.pages);
      setCurrentPage(page);
    } catch (error) {
      setErrorMessage(t("errorLoading"));
      setErrorDialog(true);
    } finally {
      setLoadingEvaluations(false);
    }
  }

  const fetchFromPage = async (page) => {
    setCurrentPage(page);
    await fetchEvaluations(page);
  }

  const fetchAfterSuccess = async () => {
    setSuccessDialog(false);
    await fetchEvaluations(currentPage)
  }

  useEffect(() => {
    fetchEvaluations(1);
  }, [])

  const addActivity = () => {
    setActivities([...activities, {
      key: activityKey,
      name: "",
      description: "",
      type: "",
      params: []
    }])
    setActivityKey(activityKey + 1)
  }

  const updateActivity = (activity, index) => {
    const activityCopy = activities.map((item, i) => {
      if (index === i) {
        return { ...activity, key: item.key };
      }
      return item
    })
    setActivities(activityCopy)
  }

  const deleteActivity = (index) => {
    const activityCopy = Array.from(activities);
    activityCopy.splice(index, 1)
    setActivities(activityCopy)
  }

  const saveEvaluation = async () => {
    setLoadingSave(true);
    const body = {
      name,
      description,
      therapistId: getUserId().userId,
      activities
    };
    try {
      await axios.post("/evaluations_new", body);
      setSuccessDialog(true)
      resetState()
    } catch (error) {
      setErrorMessage(t("error"))
      setErrorDialog(true)
    } finally {
      setLoadingSave(false);
    }
  }

  const viewEvaluation = (index) => {
    setChosenEvaluation(index);
    setDialogOpen(true);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={1} direction="column">
          <Grid item container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {t("title")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={openDialog}
              >
                {t("create")}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <EvaluationList
              evaluations={evaluations}
              loading={loadingEvaluations}
              chooseEvaluation={viewEvaluation}
            />
          </Grid>
          <Grid
            item
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Pagination
                disabled={loadingEvaluations}
                count={totalPages}
                page={currentPage}
                onChange={(_e, page) => fetchFromPage(page)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FullScreenModal
        open={dialogOpen}
        handleClose={closeDialog}
        title={chosenEvaluation === null ? t("create") : t("view")}
        submitButton={t("save")}
        onSubmit={saveEvaluation}
        canSubmit={chosenEvaluation === null}
        Content={
          <EvaluationView
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            activities={activities}
            addActivity={addActivity}
            updateActivity={updateActivity}
            deleteActivity={deleteActivity}
            evaluation={evaluations[chosenEvaluation] || null}
          />
        }
      />
      <LoadingDialog
        open={loadingSave}
        message={t("saving")}
      />
      <GenericDialog
        open={successDialog}
        handleClose={fetchAfterSuccess}
        icon={<CheckCircleOutline />}
        message={t("success")}
      />
      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={errorMessage}
      />
    </>
  )
}

export default Evaluations;