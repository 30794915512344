import React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Pagination
} from "@mui/material";
import {
  Add,
  ErrorOutline
} from "@mui/icons-material"
import { useTranslation } from "react-i18next";

const EvaluationSelectionModal = ({
  loading,
  dialogOpen,
  closeDialog,
  errorDialog,
  evaluations,
  currentPage,
  totalPages,
  getEvaluationsFromPage,
  chooseEvaluation
}) => {
  const { t } = useTranslation("patient_evaluation");

  const selectEvaluation = (item) => {
    chooseEvaluation(item)
    closeDialog()
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      disableEscapeKeyDown={loading}
    >
      <DialogContent sx={{ minWidth: "30vw" }}>
        {(loading || errorDialog) && <Grid
          container
          direction="column"
          spacing={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          {loading && <>
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2">
                {t("evaluation.loading")}
              </Typography>
            </Grid></>}
          {errorDialog && <>
            <Grid item>
              <ErrorOutline />
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2">
                {t("evaluation.error")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="text" onClick={closeDialog}>
                {t("evaluation.understood")}
              </Button>
            </Grid></>}
        </Grid>}
        {(evaluations && !loading) && <Grid container direction="column" spacing={1}>
          {/* <Grid item> //TODO: reenable search once backend supports it
            <TextField
              label={t("evaluation.search")}
              variant="outlined"
              fullWidth={true}
              disabled={true}
            />
          </Grid> */}
          <Grid item>
            <Paper sx={{ maxHeight: "60vh", overflow: "auto" }}>
              <List sx={{ width: "100%" }}>
                {evaluations.map((item) => (
                  <ListItem
                    key={item._id}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => selectEvaluation(item)}
                      >
                        <Add />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ variant: "subtitle2" }}
                      secondary={item.description}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid
            item
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid item>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={getEvaluationsFromPage}
              />
            </Grid>
          </Grid>
        </Grid>}
      </DialogContent>
    </Dialog>
  )
}

export default EvaluationSelectionModal;