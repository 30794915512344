import React from "react";
import {
  Grid,
  Dialog,
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next";

const GenericDialog = ({ open, handleClose, icon, message }) => {
  const { t } = useTranslation("dialog");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Grid
        container
        padding={2}
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Grid item padding={2}>
          {icon}
        </Grid>
        <Grid item padding={2}>
          {message}
        </Grid>
        <Grid item padding={2}>
          <Button
            variant="text"
            onClick={handleClose}
          >
            {t("understood")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default GenericDialog;