import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  AccountCircleOutlined,
} from "@mui/icons-material"

const PatientList = ({ patients, selectedPatient, updateSelectedPatient, loading }) => {
  const { t } = useTranslation("patient_evaluation");

  return <>
    {loading ? <Grid
      container
      direction="column"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        {t("patient.loadingPatients")}
      </Grid>
    </Grid> : <List>
      {patients.map((patient, index) => (
        <ListItem key={patient._id}>
          <ListItemButton
            selected={selectedPatient === index}
            onClick={() => { updateSelectedPatient(index) }}
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText
              primary={`${patient.name} ${patient.lastname}`}
              secondary={patient.rut}
            />
            <ListItemIcon>
              {(selectedPatient === index) ? <CheckCircleOutline /> : <Add />}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      ))}
    </List>}
  </>
}

export default PatientList;