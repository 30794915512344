import React, { useState } from "react"
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";

const ResultTemplateRange = ({
  activity,
  index,
  sendResults,
  activityResult = null
}) => {
  const MINIMUM = parseInt(activity.params[0].name, 10);
  const MAXIMUM = parseInt(activity.params[1].name, 10);
  const RANGE = Array(MAXIMUM - MINIMUM + 1).fill(null);


  const [result, setResult] = useState(activityResult?.params[2].value || null);

  const updateResult = (value) => {
    setResult(value);
    const newResults = [
      { paramId: activity.params[0]._id, value: activity.params[0].name },
      { paramId: activity.params[1]._id, value: activity.params[1].name },
      { paramId: activity.params[2]._id, value: MINIMUM + parseInt(value, 10) },
    ]
    sendResults(newResults, index)
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container direction="column" padding={2} spacing={1}>
          <Grid item container sx={{ justifyContent: "flex-end" }}>
            <Grid item>
              <Typography variant="subtitle1">
                {index + 1}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {activity.name}
            </Typography>
          </Grid>
          {activity.description && (<Grid item>
            <Typography variant="body2">
              {activity.description}
            </Typography>
          </Grid>)}
          <Grid item container sx={{ justifyContent: "center" }}>
            <Grid item>
              <RadioGroup
                value={result}
                row
                onChange={(e) => updateResult(e.target.value)}
              >
                {RANGE.map((value, idx) => (<FormControlLabel
                  disabled={!!activityResult}
                  key={idx}
                  value={idx}
                  control={<Radio />}
                  label={`${MINIMUM + idx}`}
                  labelPlacement="bottom"
                />))}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ResultTemplateRange;