import React, { useState } from "react"
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";

const ResultTemplateSingle = ({
  activity,
  index,
  sendResults,
  activityResult = null
}) => {
  const getResultPosition = (params) => {
    return params.findIndex((item) => item.value);
  }

  const [result, setResult] = useState(activityResult ? getResultPosition(activityResult.params) : null)

  const updateResult = (value) => {
    setResult(value)
    const newResults = activity.params.map((param, idx) => {
      return {
        paramId: param._id,
        value: parseInt(value, 10) === idx
      }
    })
    sendResults(newResults, index)
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container direction="column" padding={2} spacing={1}>
          <Grid item container sx={{ justifyContent: "flex-end" }}>
            <Grid item>
              <Typography variant="subtitle1">
                {index + 1}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {activity.name}
            </Typography>
          </Grid>
          {activity.description && (<Grid item>
            <Typography variant="body2">
              {activity.description}
            </Typography>
          </Grid>)}
          <Grid item>
            <RadioGroup
              value={result}
              onChange={(e) => updateResult(e.target.value)}
            >
              {activity.params.map((param, index) => (<FormControlLabel
                disabled={!!activityResult}
                key={param._id}
                value={index}
                control={<Radio />}
                label={param.name}
              />))}
            </RadioGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ResultTemplateSingle;